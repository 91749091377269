//
// progress.scss
//



// Utilities

@each $color,
$value in $theme-colors {
    .progress-soft:has(.bg-#{$color}) {
        background-color: rgba(var(--#{$prefix}#{$color}-rgb), 0.1);
    }
}

// Custom height

// Progress height small
.progress-sm {
    height: 5px;
}

// Progress height medium
.progress-md {
    height: 8px;
}

// Progress height large
.progress-lg {
    height: 12px;
}

// Progress height extra large
.progress-xl {
    height: 15px;
}

.progress-with-border {
    .progress-bar+.progress-bar {
        border-left: 3px solid #{$card-bg};
    }
}



// 

.progress-value {
    display: block;
    position: absolute;
    top: -28px;
    right: 0px;
}