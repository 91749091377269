//
//  _sweetalert2.scss
//

.swal2-container {
    .swal2-title {
        font-size: 20px;
        font-weight: $font-weight-medium;
        color: var(--#{$prefix}heading-color);
    }
}

.swal2-popup {
    width: 28em;
    padding: 2rem;
    border-radius: $modal-content-border-radius;
    background-color: var(--#{$prefix}secondary-bg);
    color: var(--#{$prefix}body-color);

    .swal2-html-container {
        font-size: 16px !important;
        color: var(--#{$prefix}body-color);
    }
}

.swal2-footer {
    border-top: 1px solid var(--#{$prefix}border-color);
    color: var(--#{$prefix}body-color);
}

.swal2-icon {
    
    &.swal2-question {
        border-color: $info;
        color: $info;
    }

    &.swal2-success {
        [class^=swal2-success-line] {
            background-color: $success;
        }

        .swal2-success-ring {
            border-color: $success;
        }
    }

    &.swal2-warning {
        border-color: $warning;
        color: $warning;
    }
}

div:where(.swal2-icon) {
    width: 4em;
    height: 4em;
    margin: 0.5rem auto;

    .swal2-icon-content {
        font-size: 3em;
    }

    &.swal2-info {
        border-color: $info;
        color: $info;
    }
}

.swal2-styled {
    &:focus {
        box-shadow: none;
    }
}

.swal2-loader {
    border-color: $primary transparent $primary transparent;
}

.swal2-timer-progress-bar {
    background-color: rgba($success, 0.4);
}

.swal2-progress-steps {
    .swal2-progress-step {
        background: $primary;

        &.swal2-active-progress-step {
            background: $primary;

            &~.swal2-progress-step,
            &~.swal2-progress-step-line {
                background: rgba($primary, 0.3);
            }
        }
    }

    .swal2-progress-step-line {
        background: $primary;
    }
}

.swal2-actions.swal2-loading {
    .swal2-styled.swal2-confirm {
        border-left-color: $primary !important;
        border-right-color: $primary !important;
    }
}

.swal2-file,
.swal2-input,
.swal2-textarea {
    border: 1px solid $input-border-color;

    &:focus {
        box-shadow: none;
        border-color: $input-focus-border-color;
    }
}

.swal2-input {
    height: auto;
    display: block;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;
}

.swal2-close {
    font-family: $font-family-base;
    font-size: 42px;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 5px;
    right: 5px;

    &:focus {
        box-shadow: none;
    }

    &:hover {
        color: $danger;
    }
}

.swal2-validation-message {
    background-color: transparent;
}


div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line] {
    width: 1.9375em;
    top: 1.95em;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-line][class$=tip] {
    top: 2.4em;
    width: 1em;
}

.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
    top: 1.85em;
    right: .5em;
    width: 2.25em;
}