//
// buttons.scss
//

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &-icon {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: calc(#{$btn-padding-y * 2} + #{$btn-line-height}em + #{$border-width * 2});
        width: calc(#{$btn-padding-y * 2} + #{$btn-line-height}em + #{$border-width * 2});
        padding: 0;

        :is(i, svg, img) {
            vertical-align: middle;
        }

        &.btn-sm {
            height: calc(#{$btn-padding-y-sm * 2} + #{$btn-line-height}em + #{$border-width * 2});
            width: calc(#{$btn-padding-y-sm * 2} + #{$btn-line-height}em + #{$border-width * 2});
        }

        &.btn-lg {
            height: calc(#{$btn-padding-y-lg * 2} + #{$btn-line-height}em + #{$border-width * 2});
            width: calc(#{$btn-padding-y-lg * 2} + #{$btn-line-height}em + #{$border-width * 2});
        }
    }
}

@each $state in map-keys($theme-colors) {

    .btn-soft-#{$state} {
        --#{$prefix}btn-color: var(--#{$prefix}#{$state});
        --#{$prefix}btn-bg: var(--#{$prefix}#{$state}-bg-subtle);
        --#{$prefix}btn-border-color: #{transparent};
        --#{$prefix}btn-hover-color: #{$white};
        --#{$prefix}btn-hover-bg: var(--#{$prefix}#{$state});
        --#{$prefix}btn-hover-border-color: var(--#{$prefix}#{$state});
        --#{$prefix}btn-active-color: #{$white};
        --#{$prefix}btn-active-bg: var(--#{$prefix}#{$state});
        --#{$prefix}btn-active-border-color: var(--#{$prefix}#{$state});
        --#{$prefix}btn-disabled-color: var(--#{$prefix}#{$state});
        --#{$prefix}btn-disabled-bg: var(--#{$prefix}#{$state});
        --#{$prefix}btn-disabled-border-color: var(--#{$prefix}#{$state});
        --#{$prefix}btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--#{$prefix}#{$state})-rgb, 0.5);
    }

    .btn-ghost-#{$state} {
        --#{$prefix}btn-color: var(--#{$prefix}#{$state});
        --#{$prefix}btn-bg: transparent;
        --#{$prefix}btn-border-color: #{transparent};
        --#{$prefix}btn-hover-color: var(--#{$prefix}#{$state});
        --#{$prefix}btn-hover-bg: var(--#{$prefix}#{$state}-bg-subtle);
        --#{$prefix}btn-hover-border-color: var(--#{$prefix}#{$state}-bg-subtle);
        --#{$prefix}btn-active-color: var(--#{$prefix}#{$state});
        --#{$prefix}btn-active-bg: var(--#{$prefix}#{$state}-bg-subtle);
        --#{$prefix}btn-active-border-color: var(--#{$prefix}#{$state}-bg-subtle);
        --#{$prefix}btn-disabled-color: var(--#{$prefix}#{$state}-bg-subtle);
        --#{$prefix}btn-disabled-bg: var(--#{$prefix}#{$state}-bg-subtle);
        --#{$prefix}btn-disabled-border-color: var(--#{$prefix}#{$state}-bg-subtle);
        --#{$prefix}btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--#{$prefix}#{$state})-rgb, 0.5);
    }
}

.btn-ghost-light {
    --#{$prefix}btn-color: var(--#{$prefix}secondary-color);
    --#{$prefix}btn-hover-color: var(--#{$prefix}dark);
    --#{$prefix}btn-hover-bg: var(--#{$prefix}light);
}

.btn-outline-white {
    --#{$prefix}btn-color: var(--#{$prefix}dark);
    --#{$prefix}btn-border-color: var(--#{$prefix}light);
    --#{$prefix}btn-hover-color: var(--#{$prefix}dark);
    --#{$prefix}btn-hover-bg: var(--#{$prefix}light);
    --#{$prefix}btn-hover-border-color: var(--#{$prefix}light);
    --#{$prefix}btn-active-color: var(--#{$prefix}dark);
    --#{$prefix}btn-active-bg: var(--#{$prefix}light);
    --#{$prefix}btn-active-border-color: var(--#{$prefix}light);
    --#{$prefix}btn-disabled-color: var(--#{$prefix}dark);
    --#{$prefix}btn-disabled-bg: var(--#{$prefix}light);
    --#{$prefix}btn-disabled-border-color: var(--#{$prefix}light);
}

// .btn-light {
//     --#{$prefix}btn-color: var(--#{$prefix}dark);
//     --#{$prefix}btn-bg: var(--#{$prefix}light);
//     --#{$prefix}btn-border-color: #{transparent};
//     --#{$prefix}btn-hover-color: var(--#{$prefix}tertiary-color);
//     --#{$prefix}btn-hover-bg: var(--#{$prefix}tertiary-bg);
//     --#{$prefix}btn-hover-border-color: var(--#{$prefix}tertiary-bg);
//     --#{$prefix}btn-active-bg: var(--#{$prefix}tertiary-bg);
//     --#{$prefix}btn-active-color: var(--#{$prefix}tertiary-color);
//     --#{$prefix}btn-active-border-color: var(--#{$prefix}tertiary-bg);
//     --#{$prefix}btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--#{$prefix}light-rgb), 0.5);
// }

// .btn-dark {
//     --#{$prefix}btn-color: var(--#{$prefix}light);
//     --#{$prefix}btn-bg: var(--#{$prefix}dark);
//     --#{$prefix}btn-border-color: #{transparent};
//     --#{$prefix}btn-hover-color: var(--#{$prefix}light);
//     --#{$prefix}btn-hover-bg: var(--#{$prefix}dark);
//     --#{$prefix}btn-hover-border-color: var(--#{$prefix}dark);
//     --#{$prefix}btn-active-bg: var(--#{$prefix}dark);
//     --#{$prefix}btn-active-color: var(--#{$prefix}light);
//     --#{$prefix}btn-active-border-color: var(--#{$prefix}dark);
//     --#{$prefix}btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--#{$prefix}dark-rgb), 0.5);
// }

// .btn-outline-light {
//     --#{$prefix}btn-color: var(--#{$prefix}dark);
//     --#{$prefix}btn-bg: #{transparent};
//     --#{$prefix}btn-border-color: var(--#{$prefix}light);
//     --#{$prefix}btn-hover-color: var(--#{$prefix}tertiary-color);
//     --#{$prefix}btn-hover-bg: var(--#{$prefix}tertiary-bg);
//     --#{$prefix}btn-hover-border-color: var(--#{$prefix}tertiary-bg);
//     --#{$prefix}btn-active-bg: var(--#{$prefix}tertiary-bg);
//     --#{$prefix}btn-active-color: var(--#{$prefix}tertiary-color);
//     --#{$prefix}btn-active-border-color: var(--#{$prefix}tertiary-bg);
//     --#{$prefix}btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--#{$prefix}light-rgb), 0.5);
// }

// .btn-outline-dark {
//     --#{$prefix}btn-color: var(--#{$prefix}dark);
//     --#{$prefix}btn-bg: #{transparent};
//     --#{$prefix}btn-border-color: var(--#{$prefix}dark);
//     --#{$prefix}btn-hover-color: var(--#{$prefix}light);
//     --#{$prefix}btn-hover-bg: var(--#{$prefix}dark);
//     --#{$prefix}btn-hover-border-color: var(--#{$prefix}dark);
//     --#{$prefix}btn-active-bg: var(--#{$prefix}dark);
//     --#{$prefix}btn-active-color: var(--#{$prefix}light);
//     --#{$prefix}btn-active-border-color: var(--#{$prefix}dark);
//     --#{$prefix}btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--#{$prefix}dark-rgb), 0.5);
// }

// .btn-soft-dark {
//     --#{$prefix}btn-hover-color: var(--#{$prefix}light);
//     --#{$prefix}btn-active-color: var(--#{$prefix}light);
// }

[data-bs-theme="dark"] {
    .btn-secondary {
        box-shadow: var(--#{$prefix}btn-box-shadow) rgba(var(--#{$prefix}light-rgb), 0.5);
    }
}


$background-color_1: hsla(0, 0%, 50%, .1);

.h-e-circle {
    position: relative;

    &:before {
        background-color: $background-color_1;
        border-radius: 50%;
        content: "";
        inset: -2px;
        opacity: 0;
        position: absolute;
        transform: scale(0);
        transition: all .15s cubic-bezier(.4, 0, .2, 1);
    }

    &:hover {
        &:before {
            opacity: 1;
            transform: scale(1);
        }
    }

    &.show {
        &:before {
            opacity: 1;
            transform: scale(1);
        }
    }
}


.h-e-circle-lg {
    position: relative;

    &:before {
        background-color: $background-color_1;
        border-radius: 50%;
        content: "";
        inset: -8px;
        opacity: 0;
        position: absolute;
        transform: scale(0);
        transition: all .15s cubic-bezier(.4, 0, .2, 1);
    }

    &:hover {
        &:before {
            opacity: 1;
            transform: scale(1);
        }
    }

    &.show {
        &:before {
            opacity: 1;
            transform: scale(1);
        }
    }
}


.h-e-rounded {
    position: relative;

    &:before {
        background-color: $background-color_1;
        border-radius: 4px;
        content: "";
        inset: -2px;
        opacity: 0;
        position: absolute;
        transform: scale(0);
        transition: all .15s cubic-bezier(.4, 0, .2, 1);
    }

    &:hover {
        &:before {
            opacity: 1;
            transform: scale(1);
        }
    }

    &.show {
        &:before {
            opacity: 1;
            transform: scale(1);
        }
    }
}


.h-e-rounded-lg {
    position: relative;

    &:before {
        background-color: $background-color_1;
        border-radius: 4px;
        content: "";
        inset: -8px;
        opacity: 0;
        position: absolute;
        transform: scale(0);
        transition: all .15s cubic-bezier(.4, 0, .2, 1);
    }

    &:hover {
        &:before {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.h-e-rounded-lg.show {
    &:before {
        opacity: 1;
        transform: scale(1);
    }
}