//
// _choices.scss
//

.choices {

    // data-type = select-one
    &[data-type*="select-one"] {
        cursor: pointer;

        .choices__input {
            padding: $input-padding-y $input-padding-x !important;
            background-color: $input-bg;
            border: 1px solid $input-border-color;
            font-size: $input-font-size;
            border-radius: $input-border-radius;
            color: $input-color;
            margin-bottom: 10px;
        }

        &:after {
            content: "\ea5f";
            position: absolute;
            border: 0;
            top: 18px;
            right: 12px;
            left: auto;
            font-size: 20px;
            font-family: tabler-icons;
            width: auto;
            height: auto;
            margin-top: 0;
            transform: translateY(-50%);
            transition: transform 0.2s ease-in-out;
            pointer-events: none;
        }

        &.is-open:after {
            margin-top: 0;
            transform: translateY(-50%) rotate(-180deg);
        }

        .choices__button {
            right: 20px;
        }
    }

    // data-type = select- multiple & text
    &[data-type*="select-multiple"],
    &[data-type*="text"] {
        .choices__inner {
            cursor: text;
            padding: $input-padding-y * 0.5 $input-padding-x * 0.5 !important;
        }

        .choices__button {
            border-left: 1px solid rgba($white, 0.5);
            border-right: 0;
        }

        .choices__list--dropdown {
            padding-bottom: 10px;

            .choices__list {
                margin-bottom: 0;
            }
        }
    }

    &[data-type*="select-multiple"] {
        .choices__input {
            padding-top: 3px;
        }
    }
}

// Select Light
.input-light {
    .choices__inner {
        background-color: var(--#{$prefix}light);
        border: none;
    }
}

.choices__inner {
    padding: $input-padding-y $input-padding-x !important;
    vertical-align: middle;
    background-color: $form-select-bg;
    border: $form-select-border-width solid $form-select-border-color;
    border-radius: $form-select-border-radius !important;
    font-size: $input-font-size;
    min-height: auto;

    .choices__list--single {
        padding: 0 !important;
    }
}

.is-focused,
.is-open {
    .choices__inner {
        border-color: $input-focus-border-color;
    }
}

.choices__list--multiple {
    .choices__item {
        vertical-align: initial;
        border-radius: 7px;
        padding: 2px 7px;
        font-size: 11px;
        font-weight: $font-weight-normal;
        margin: 2px 5px 2px 0;
        background-color: $primary;
        border: 1px solid $primary;
        color: $white;
        box-shadow: $box-shadow-sm;

        &.is-highlighted {
            background-color: $primary;
            border: 1px solid $primary;
        }
    }
}

.is-disabled {
    .choices__list--multiple {
        .choices__item {
            background-color: $secondary;
            border: 1px solid $secondary;
        }
    }
}

.choices.is-disabled .choices__inner, .choices.is-disabled .choices__input {
    background-color: var(--#{$prefix}tertiary-bg);
}

.choices__list--dropdown {
    padding: 10px 10px 20px 10px;
    background-color: $card-bg !important;
    border: 1px solid transparent !important;
    box-shadow: $dropdown-box-shadow;
    margin-top: 0 !important;
    border-radius: $dropdown-border-radius;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform: translateY(1px);

    &.is-active {
        animation-name: DropDownSlide;
    }

    .choices__item--selectable.is-highlighted {
        background-color: $dropdown-link-hover-bg !important;
    }

    .choices__list {
        margin: 0 -10px -16px;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 12px;
            margin-right: 15px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--#{$prefix}dark-rgb), 0.1);
            border-radius: 10px;
            border: 2px solid $card-bg;
        }

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: $card-bg;
        }
    }

    .has-no-results {
        font-style: italic;
        font-weight: $font-weight-medium;
    }

    .choices__item--selectable:after {
        display: none;
    }
}

.is-open {
    .choices__list--dropdown {
        border-color: transparent;
    }
}

.is-flipped {
    .choices__list--dropdown {
        &.is-active {
            border-radius: $dropdown-border-radius;
            animation-name: DropDownSlideDown;
            transform: translateY(-1px);
        }
    }
}

.choices__list--dropdown {
    .choices__list {
        max-height: 300px;
    }

    .choices__item {
        font-size: $dropdown-font-size;
        padding: $dropdown-item-padding-y $dropdown-item-padding-x $dropdown-item-padding-y 16px;
    }
}

.choices__heading {
    font-weight: $font-weight-semibold;
    padding: 10px 16px;
    border-bottom: 1px solid $border-color;
    color: $text-muted;
}

.choices__input {
    background-color: $input-bg;
    color: $input-color;
    font-size: $input-font-size;
    margin-bottom: 0;
    padding: 2px 0 2px 2px;

    &::placeholder {
        color: $input-placeholder-color;
    }
}

.choices__placeholder {
    color: $input-placeholder-color;
    opacity: 1;
}

[data-bs-theme="dark"] {
    .choices[data-type*="select-one"] {
        .choices__button {
            filter: $btn-close-white-filter;
        }
    }
}
