//
// _email.scss
//

@include media-breakpoint-up(xxl) {
    .email-sidebar {
        width: 270px;
    }
}


.email-menu-list {
    a {
        color: var(--#{$prefix}body-tertiary-color);
        font-weight: 500;
        padding: 8px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in-out;

        &.active,
        &:hover {
            color: var(--#{$prefix}primary);
            background-color: rgba(var(--#{$prefix}light-rgb), 0.75);

            iconify-icon.text-muted {
                color: var(--#{$prefix}primary) !important;
            }
        }

        &.active {
            font-weight: $font-weight-bold;
        }
    }
}


.mail-list:has(.form-check-input:checked) {
    tr:has(.form-check-input:checked) {
        background-color: rgba(var(--#{$prefix}light-rgb), 0.5);
    }
}