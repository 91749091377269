//
// maps.scss
//

//Google Maps

.gmaps,
.gmaps-panaroma {
    height: 300px;
    background: var(--#{$prefix}tertiary-bg);
    border-radius: 3px;
}

// Vector Map
.jvm-container {
    text {
        font-family: $font-family-base !important;
        fill: var(--#{$prefix}body-color);
    }
}

.jvm-tooltip {
    background-color: $primary;
    font-family: $font-family-base !important;
}
