//
// faq.scss
//

.faq-question-q-box {
    height: 30px;
    width: 30px;
    color: var(--#{$prefix}primary);
    background-color: rgba(var(--#{$prefix}primary-rgb), 0.25);
    box-shadow: $box-shadow-lg;
    text-align: center;
    border-radius: 50%;
    float: left;
    font-weight: $font-weight-bold;
    line-height: 30px;
}

.faq-question {
    margin-top: 0;
    margin-left: 50px;
    font-weight: $font-weight-semibold;
    font-size: 16px;
    color: var(--#{$prefix}tertiary-color);
}

.faq-answer {
    margin-left: 50px;
}

.docs-nav {
    @extend .d-xl-flex;
    @extend .d-none;
    position: sticky;
    right: 0;
    z-index: 2;
    overflow-y: auto;
    padding: 8px 0;

    .nav-item {
        .nav-link {
            color: $text-muted;
            font-weight: $font-weight-medium;
            transition: all 0.1s ease-out;

            &:hover {
                color: $primary;
            }
        }

        &.active .nav-link {
            color: $primary;
            text-underline-offset: 0.35rem;
            text-decoration-line: underline;
        }
    }
}

.anchor {
    scroll-margin-top: 108px;

    .anchor-link {
        padding: 0 0.175rem;
        font-weight: 400;
        color: $primary;
        text-decoration: none;
        opacity: 0;
        transition: color 0.15s ease-in-out, opacity 0.15s ease-in-out;
    }

    &:hover,
    &:focus {
        .anchor-link {
            text-decoration: none;
            opacity: 1;
        }
    }
}

.docs-nav {
    top: 5.5rem;
}

.terms-bg{
    background-image: linear-gradient(rgba(255, 255, 255, .031372549) 2px, transparent 2px), linear-gradient(to right, rgba(255, 255, 255, .031372549) 2px, transparent 2px);
    background-size: 50px 50px;
    position: relative;
}