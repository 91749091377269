// Background lighten
@each $state in map-keys($theme-colors) {
    .bg-soft-#{$state} {
        background-color: rgba(var(--#{$prefix}#{$state}-rgb), 0.15) !important;
    }
}

// Utilities

@each $color,
$value in $theme-colors {
    .fill-#{$color} {
        fill: rgba(var(--#{$prefix}#{$color}), 0.1);
    }
}


// Background gradient
@each $color, $value in $theme-colors {
    .bg-#{$color}-gradient {
        background-image: linear-gradient(120deg, #{darken($value, 12%)} 0%, #{darken($value, 4%)} 60%, #{darken($value, 0%)} 100%);
    }
}