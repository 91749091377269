//
// modal.scss
//

// Title text within header
.modal-title {
    color: inherit;
}

// Modal full width
.modal-full-width {
    width: 95%;
    max-width: none;
}

// Modal Positions
.modal-top {
    margin: 0 auto;
}

.modal {
    &.show {
        .modal-right,
        .modal-left {
            transform: translate(0, 0) !important;
        }
    }
}

// Bottom modal
.modal-bottom {
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    margin: 0 auto;
    align-content: center;
}

// Full filled modals
.modal-filled {
    color: $white;
    
    .modal-header {
        background-color: rgba($white, 0.1);
    }
    
    .modal-title {
        color: $white;
    }

    .modal-header,
    .modal-footer {
        border: none;
    }
}

.modal-backdrop {
    backdrop-filter: blur(10px);
}