//
// _sidenav.scss
//

.sidenav-menu {
    z-index: 1000;
    position: fixed;
    bottom: 0;
    top: 0;
    width: $sidenav-width;
    background: $menu-bg;
    box-shadow: var(--#{$prefix}box-shadow);
    border-right: $card-border-width solid $card-border-color;
    transition: $transition-base;

    [data-simplebar] {
        height: calc(100% - $topbar-height);
    }
}

// Side-nav
.side-nav {
    padding-left: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .side-nav-item {
        .side-nav-link {
            display: flex;
            align-items: center;
            gap: $menu-link-item-gap;
            position: relative;
            white-space: nowrap;
            color: $menu-item-color;
            transition: $transition-base;
            line-height: $menu-item-font-size;
            font-size: $menu-item-font-size;
            padding: $menu-item-padding-y $menu-item-padding-x;
            font-weight: $font-weight-medium;

            &:hover,
            &:focus,
            &:active {
                color: $menu-item-hover-color;
            }

            .menu-icon {
                font-size: $menu-item-icon-size;
                line-height: $menu-item-icon-size;

                i {
                    font-size: $menu-item-icon-size;
                    line-height: $menu-item-icon-size;
                }

                svg {
                    height: $menu-item-icon-size;
                    width: $menu-item-icon-size;
                }
            }

            .badge {
                margin-left: auto;
            }
        }
    }

    >.side-nav-item {
        padding: 0 10px;
    }

    // Multi Level Menu
    .sub-menu {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-left: 0;
        margin-top: 5px;

        .side-nav-item {
            .side-nav-link {
                font-size: calc($menu-item-font-size * 0.95);
                padding: calc($menu-item-padding-y * 0.95) calc($menu-item-padding-x * 1.5) calc($menu-item-padding-y * 0.95) calc($menu-item-icon-size + $menu-item-padding-x + $menu-link-item-gap);
            }
        }

        .sub-menu {
            padding-left: 12px;
        }
    }

    .side-nav-title {
        letter-spacing: 0.1em;
        pointer-events: none;
        cursor: default;
        white-space: nowrap;
        text-transform: uppercase;
        color: $menu-item-color;
        font-weight: $font-weight-bold;
        font-size: calc($menu-item-font-size * 0.7);
        padding: $menu-item-padding-y calc($menu-item-padding-x * 1.75);
    }

    // Active Menu
    >.side-nav-item.active {
        .menu-arrow {
            transform: rotate(90deg);
        }

        >a,
        .side-nav-link.active {
            color: $menu-item-hover-color;
            font-weight: $font-weight-medium;
        }
    }
}

.menu-arrow {
    margin-left: auto;
    transition: transform .1s ease-in-out;
    // transition: $transition-base;

    &:before {
        content: "\ea61";
        font-family: "tabler-icons";
    }
}

[aria-expanded="true"] {
    .menu-arrow {
        transform: rotate(90deg);
    }
}

//  Link Dot
.sub-menu {
    .side-nav-link::before {
        content: "";
        content: "\f671";
        font-family: "tabler-icons";
        font-size: $menu-item-icon-size;
        opacity: 0.5;
        position: absolute;
        top: 50%;
        left: $menu-item-padding-x;
        transform: translateY(-50%) scale(0.25);
        transition: $transition-base;
    }
}

// Active Link  Background
.side-nav {
    .side-nav-item {
        .side-nav-link {
            border-radius: var(--#{$prefix}border-radius);

            &:hover,
            &:focus,
            &:active {
                background-color: $menu-item-hover-bg;
            }
        }

        .sub-menu {
            margin-top: 4px;
        }
    }

    >.side-nav-item.active {
        .side-nav-link.active {
            color: $menu-item-active-color;
            background: $menu-item-active-bg;
        }
    }
}

// Enlarge Menu (Condensed md size left sidebar )
html[data-sidenav-size="condensed"]:not([data-layout="topnav"]) {

    .app-topbar {
        z-index: 1005;
        margin-left: $sidenav-width-sm;
    }

    .page-content {
        margin-left: $sidenav-width-sm;
        min-height: 1800px;
    }

    .sidenav-menu {
        position: absolute;
        width: $sidenav-width-sm;

        .simplebar-mask,
        .simplebar-content-wrapper {
            overflow: visible !important;
        }

        .simplebar-scrollbar,
        .menu-alert {
            display: none !important;
        }

        .simplebar-offset {
            bottom: 0 !important;
        }

        .logo {
            z-index: 1;
            background: $menu-bg;

            span.logo-lg {
                display: none;
            }

            span.logo-sm {
                display: block;
            }
        }

        .side-nav {

            >.side-nav-item {
                padding: 0px;
            }

            .side-nav-item {
                position: relative;

                .side-nav-link {
                    transition: none;
                    border-radius: 0;
                    padding: calc($menu-item-padding-y * 1.5) $menu-item-padding-x;

                    .menu-text,
                    .menu-arrow,
                    .badge {
                        display: none;
                    }

                    .menu-icon {
                        display: flex;
                        justify-content: center;
                        min-width: calc($sidenav-width-sm - calc($menu-item-padding-x * 2));

                        i {
                            font-size: calc($menu-item-icon-size * 1.1);
                        }
                    }

                    .menu-arrow {
                        transform: rotate(90deg);
                    }
                }

                .collapse,
                .collapsing {
                    display: none;
                    height: inherit !important;
                    transition: none !important;

                    .sub-menu {
                        display: none;
                        padding: 0;
                        margin-top: 0;
                        position: absolute;
                        left: $sidenav-width-sm;
                        box-shadow: var(--#{$prefix}box-shadow);
                        border: $card-border-width solid $card-border-color;
                        border-top: 0;
                        background-color: $menu-bg;
                        background-repeat: no-repeat, no-repeat;
                        background-position: right top, left bottom;
                        background-size: 50%, 50%;
                    }
                }

                &:hover {
                    .side-nav-link {
                        position: relative;

                        .menu-text,
                        .menu-arrow,
                        .badge {
                            display: flex;
                            // visibility: visible;
                        }

                        .side-nav-link::before {
                            display: none;
                        }
                    }

                    >.collapse,
                    >.collapsing {
                        display: block !important;
                        transition: none !important;

                        >.sub-menu {
                            display: block;
                            left: $sidenav-width-sm;
                            width: calc(#{$sidenav-width} - #{$sidenav-width-sm});

                            .side-nav-item:hover {

                                >.collapse,
                                >.collapsing {

                                    >.sub-menu {
                                        top: 0;
                                        left: calc(#{$sidenav-width} - #{$sidenav-width-sm});
                                        width: calc(#{$sidenav-width} - #{$sidenav-width-sm});
                                    }
                                }
                            }
                        }
                    }
                }
            }

            >.side-nav-item:hover {
                >.side-nav-link {
                    color: $white;
                    background-color: $primary;
                    backdrop-filter: blur(10px);
                    width: $sidenav-width;

                    // .menu-text {
                    //     padding-left: 28px;
                    // }

                    &[data-bs-toggle="collapse"] {
                        border-end-end-radius: 0;
                    }
                }
            }
        }
    }

    .side-nav-link::before,
    .help-box,
    .side-nav-title {
        display: none;
    }
}

//  Compact Menu
html[data-sidenav-size="compact"]:not([data-layout="topnav"]) {

    .sidenav-menu {
        width: $sidenav-width-md;

        .side-nav {

            .side-nav-title {
                text-align: center;
            }

            .side-nav-link {
                flex-direction: column;
                justify-content: center;

                .side-nav-link::before,
                .badge {
                    display: none;
                }
            }

            .sub-menu {
                padding: 0;

                .side-nav-link {
                    flex-direction: row;
                    padding: $menu-item-padding-y $menu-item-padding-x;

                    .menu-arrow {
                        margin: 0;
                        display: inline-flex;
                    }

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    .page-content,
    .app-topbar {
        margin-left: $sidenav-width-md;
    }

    .menu-arrow,
    .help-box {
        display: none;
    }
}

// Hover View Menu
html[data-sidenav-size="sm-hover"]:not([data-layout="topnav"]) {

    .page-content,
    .app-topbar {
        margin-left: $sidenav-width-sm;
    }

    .sidenav-toggle-button {
        display: none;
    }

    .sidenav-menu:not(:hover) {
        width: $sidenav-width-sm;

        .simplebar-scrollbar:before {
            background: transparent;
        }

        .logo {
            .logo-sm {
                display: block;
            }

            .logo-lg {
                display: none;
            }
        }

        .side-nav-item {
            .side-nav-link {
                justify-content: center;

                .menu-text,
                .menu-arrow,
                .badge {
                    display: none;
                }
            }
        }

        .side-nav-title {
            display: none;
        }

        .button-sm-hover,
        .menu-text {
            // display: none;
            visibility: hidden;
        }

        .sub-menu {
            height: 0;
            opacity: 0;
        }
    }

    .sidenav-menu {
        .simplebar-horizontal .simplebar-scrollbar:before {
            background: transparent;
        }

        .logo {
            text-align: left;
        }

        &:hover {
            z-index: 1045;
        }

        .button-sm-hover {
            display: block;
        }

        .help-box {
            display: none;
        }
    }
}

// Sidebar sm hover Toggle Menu Button (sm hover active button)
.button-sm-hover {
    cursor: pointer;
    display: none;
    right: 12px;
    top: 0;
    position: absolute;
    font-size: $menu-item-icon-size;
    line-height: $topbar-height;
    background: transparent;
    border: none;
    z-index: 1;
    color: $menu-item-color;

    &:hover,
    &:focus,
    &:active {
        color: $menu-item-hover-color;
    }
}

// Fullscreen Close Button
.button-close-fullsidebar {
    display: none;
    z-index: 1;
    position: absolute;
    background: transparent;
    border: none;
    top: 0;
    right: calc($sidenav-width-sm * 0.5 - $logo-sm-height * 0.7);
    cursor: pointer;
    color: $menu-item-color;
    line-height: $topbar-height;
    font-size: $menu-item-icon-size;

    &:hover,
    &:focus,
    &:active {
        color: $menu-item-hover-color;
    }
}

html[data-sidenav-size="sm-hover-active"]:not([data-layout="topnav"]) {

    .sidenav-toggle-button {
        display: none;
    }

    .sidenav-menu {

        .logo {
            text-align: left;
        }

        .button-sm-hover {
            display: block;

            i.ti-circle {
                &::before {
                    content: "\efb1";
                }
            }
        }
    }
}

// Full Size Menu
html[data-sidenav-size="full"]:not([data-layout="topnav"]) {

    .page-content,
    .app-topbar {
        margin-left: 0;
    }

    .button-close-fullsidebar {
        display: block;
    }

    .sidenav-menu {
        margin-left: calc($sidenav-width * -1);
        opacity: 0;

        .logo {
            text-align: left;
        }
    }

    &.sidebar-enable {
        .sidenav-menu {
            opacity: 1;
            z-index: 1055;
            margin-left: 0;
        }
    }
}

// Fullscreen Menu
html[data-sidenav-size="fullscreen"]:not([data-layout="topnav"]) {

    .sidenav-menu {
        margin-left: calc($sidenav-width * -1);
        opacity: 0;
    }

    .page-content,
    .app-topbar {
        margin-left: 0;
    }
}

// Detached Left Sidebar Menu (Layout Mode)
html[data-layout-mode="detached"]:not([data-layout="topnav"]) {

    .wrapper {
        max-width: 95%;
        margin: 0 auto;

        .app-topbar {
            margin: 0 -2.65%;
            z-index: 1005;

            .logo {
                display: inline;
            }

            .topbar-menu {
                max-width: 95%;
            }
        }

        .sidenav-menu {
            padding: 12px 0;
            bottom: $spacer * 0.5;
            top: calc(#{$topbar-height} + #{$spacer} * 0.5);
            border-radius: $border-radius-lg;
            border: $card-border-width solid $card-border-color;
            padding-bottom: 0;

            .logo {
                visibility: hidden;
                height: 0;
            }
        }
    }

    &[data-sidenav-size="full"] {
        .sidenav-menu {
            top: $spacer * 0.5;

            .logo {
                top: $spacer * 0.5;
                visibility: visible;
                height: auto;

                span.logo-lg {
                    display: block;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    html[data-layout-mode="detached"] {
        .wrapper {
            max-width: 100% !important;

            .app-topbar {
                margin: 0;
                padding: 0;
            }
        }
    }
}