//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
    a {
        color: var(--#{$prefix}body-color);

        &:hover {
            color: var(--#{$prefix}primary);
        }
    }
    +.breadcrumb-item {
        &::before {
            font-family: "tabler-icons";
        }
    }
}