//
// print.scss
//

// Used invoice page
@media print {
    .sidenav-menu,
    .page-title-head,
    .app-topbar,
    .footer {
        display: none !important;
    }
    .card-body,
    .page-content,
    body {
        padding: 0;
        margin: 0;
    }

    .card {
        box-shadow: none;
    }
}
