//
// _horizontal-nav.scss
//

html[data-layout="topnav"] {
    .topnav {
        width: 100%;
        z-index: 1000;
        position: sticky;
        top: $topbar-height;
        background: $menu-bg;
        box-shadow: var(--#{$prefix}box-shadow);
        border-top: 1px solid $topbar-user-border;

        .navbar {
            margin: 0;
            padding: 0;
        }

        .navbar-nav {
            .nav-link {
                display: flex;
                align-items: center;
                position: relative;
                font-size: $menu-item-font-size;
                font-weight: $font-weight-medium;
                padding: calc($menu-item-padding-y * 2) calc($menu-item-padding-x * 2);

                .menu-icon {
                    margin-right: calc($menu-item-icon-size * 0.75);
                    line-height: $menu-item-icon-size;

                    i {
                        font-size: $menu-item-icon-size;
                    }

                    svg {
                        height: $menu-item-icon-size;
                        width: $menu-item-icon-size;
                    }
                }


                .menu-arrow {
                    margin-left: calc($menu-item-icon-size * 0.75);
                    margin-top: 2px
                }
            }

            .dropdown {
                .dropdown-menu {
                    @include font-size(calc(#{$dropdown-font-size} - 0.01rem));

                    .dropdown-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: calc($menu-item-padding-y * 1) calc($menu-item-padding-x * 1);
                    }

                }
            }
        }

        .nav-item {

            >a,
            .nav-link {
                color: $menu-item-color;

                &:hover {
                    color: $menu-item-hover-color;
                }
            }

            &.active {
                >a {
                    color: $menu-item-hover-color;
                }
            }

        }
    }

    .app-topbar {
        margin: 0;
        padding: 0;
        z-index: 1005;
        box-shadow: none;

        .sidenav-toggle-button {
            display: none;
        }

        .logo {
            display: flex;

            .logo-light {
                display: none;
            }

            .logo-dark {
                display: block;
            }
        }
    }

    .page-content {
        padding: 0;
        margin-left: 0;
        min-height: calc(100vh - $topbar-height);
    }
}

// Topnav Logo
html[data-topbar-color="brand"],
html[data-topbar-color="dark"],
html[data-bs-theme="dark"] {
    .app-topbar {
        .logo {
            .logo-light {
                display: block;
            }

            .logo-dark {
                display: none;
            }
        }
    }
}

// Horizontal Menu Toggle Button
.topnav-toggle-button {
    display: none;
    position: relative;
    cursor: pointer;
    float: left;
    padding: 0;
    background-color: transparent;
    border: none;
    color: $topbar-item-color;
}


@include media-breakpoint-down(lg) {
    html[data-layout="topnav"] {
        .topnav {
            max-height: 360px;
            overflow-y: auto;

            .navbar-nav {
                .nav-link {
                    padding: calc($menu-item-padding-y * 0.8) calc($menu-item-padding-x * 2);
                }
            }

            .dropdown {
                .dropdown-menu {
                    background-color: transparent;
                    border: none;
                    box-shadow: none;
                    padding-left: calc($menu-item-padding-x * 3);
                    margin-top: calc($menu-item-padding-y * -0.75);

                    .dropdown-menu {
                        margin-top: calc($menu-item-padding-y * -0.5);
                        padding-left: calc($menu-item-padding-x * 1.5);
                    }
                }

                .dropdown-item {
                    position: relative;
                    background-color: transparent;
                }
            }

            .dropdown {
                .dropdown-item {
                    color: $menu-item-color;

                    &.hover {
                        color: $menu-item-hover-color;
                    }

                    &.active,
                    &:active {
                        color: $menu-item-active-color;
                    }
                }
            }

            .dropdown.active>a.dropdown-item {
                color: $menu-item-active-color;
            }

            .arrow-down {
                font-size: $menu-item-font-size;
                transform: rotate(270deg);
                position: absolute;
                right: $menu-item-padding-x;
            }
        }

        .topnav-toggle-button {
            display: flex;
        }

        .topnav-toggle-button {
            display: block;
        }
    }
}

@include media-breakpoint-up(lg) {
    .topnav {
        .navbar-nav {
            .nav-item {
                &:first-of-type {
                    .nav-link {
                        padding-left: 0;
                    }
                }
            }
        }

        .dropdown {
            .dropdown-menu {
                margin-top: 0;
                min-width: calc(var(--#{$prefix}dropdown-min-width) + calc($menu-item-font-size * 1.5));
                @include font-size(calc(#{$dropdown-font-size} - 0.01rem));

                .arrow-down {
                    position: absolute;
                    transform: rotate(270deg);
                    font-size: $menu-item-font-size;
                    right: $menu-item-padding-x;
                }

                .dropdown {
                    .dropdown-menu {
                        position: absolute;
                        top: 0;
                        left: calc(100% + 8px);
                        display: none;

                        &.show {
                            display: block;
                        }
                    }
                }
            }

            &:hover {
                >.dropdown-menu {
                    display: block;

                    .dropdown-item:hover {
                        position: relative;

                        &::before {
                            content: "";
                            inset: 0;
                            position: absolute;
                            width: $sidenav-width;
                        }
                    }
                }
            }
        }

        .dropdown:hover>.dropdown-menu>.dropdown:hover>.dropdown-menu {
            display: block;
        }



        .dropdown.active>a.dropdown-item {
            color: $dropdown-link-active-color;
            @include gradient-bg($dropdown-link-active-bg);
        }
    }
}

@include media-breakpoint-up(xxl) {
    html[data-layout="topnav"] {
        .page-container {
            max-width: 85%;
        }
    }
}