//
// _layout.scss
//

// Wrapper
.wrapper {
    height: 100%;
    width: 100%;
}

// Content Page
.page-content {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: $sidenav-width;
    transition: $transition-base;
    min-height: calc(100vh - $topbar-height);
    padding: 0 calc($grid-gutter-width * 0.5);
}

// Logo Height
.logo-lg {
    img {
        height: $logo-lg-height;
    }
}

.logo-sm {
    img {
        height: $logo-sm-height;
    }
}

// Sidebar Logo
.logo {
    display: block;
    top: 0;
    position: sticky;
    line-height: $topbar-height;
    padding: 0 22px;
    transition: $transition-base;

    .logo-lg {
        display: block;
    }

    .logo-sm {
        display: none;
    }

    .logo-light {
        display: none;
    }

    .logo-dark {
        display: block;
    }
}

// Sidebar Bottom Help-box
.help-box {
    border-radius: 5px;
    padding: 20px;
    margin: 65px 25px 25px;
    position: relative;
    background-color: rgba(var(--#{$prefix}light-rgb), 0.5);
}

html[data-bs-theme="dark"] {
    .logo {
        .logo-light {
            display: block;
        }

        .logo-dark {
            display: none;
        }
    }
}

// Sidenav Logo
html[data-menu-color="brand"],
html[data-menu-color="dark"] {

    .sidenav-menu {
        .logo {
            .logo-light {
                display: block;
            }

            .logo-dark {
                display: none;
            }
        }

        .help-box {
            color: $white;
            background-color: rgba(var(--#{$prefix}light-rgb), 0.07);
        }
    }
}


// Topbar Logo
html[data-topbar-color="brand"],
html[data-topbar-color="dark"] {
    .app-topbar {
        .logo {
            .logo-light {
                display: block;
            }

            .logo-dark {
                display: none;
            }
        }
    }
}