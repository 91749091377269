//
// form-wizard.scss
//

.form-wizard-header {
    margin-left: calc(var(--#{$prefix}card-spacer-x) * -1);
    margin-right: calc(var(--#{$prefix}card-spacer-x) * -1);
    background-color: var(--#{$prefix}light);
}


//
// Img Crop
// 


.img-preview {
    float: left;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    overflow: hidden;
}

.img-preview>img {
    max-width: 100%;
}

.preview-lg {
    height: 9rem;
    width: 16rem;
}

.preview-md {
    height: 4.5rem;
    width: 8rem;
}

.preview-sm {
    height: 2.25rem;
    width: 4rem;
}

.preview-xs {
    height: 1.125rem;
    margin-right: 0;
    width: 2rem;
}
