// 
// _gridjs.scss
// 

.gridjs-container {
    color: $table-color;
    padding: 0;
    display: block;
}

.gridjs-wrapper {
    box-shadow: none;
    border: 1px solid $table-border-color;
    border-radius: 0px;
    scrollbar-width: thin;
}

.gridjs-footer {
    border: none !important;
    padding: 12px 0 0;
}

.gridjs-table {
    width: 100%;
}

.gridjs-tbody,
td.gridjs-td {
    background-color: $table-bg;
}

th.gridjs-th,
td.gridjs-td {
    border: 1px solid $table-border-color;
    padding: $table-cell-padding-y $table-cell-padding-x;
}

th {
    &.gridjs-th {
        border-top: 0;
        color: $table-color;
        background-color: rgba(var(--#{$prefix}light-rgb), 1);

    }

    &.gridjs-th-sort {

        &:focus,
        &:hover {
            background-color: rgba(var(--#{$prefix}light-rgb), .85);
        }
    }
}

.gridjs-head {
    padding-top: 0;
}

.gridjs-footer {
    box-shadow: none;
    border: 1px solid $table-border-color;
    border-top: 0;
    background-color: $table-bg;
}

.gridjs-summary {
    color: var(--#{$prefix}secondary-color);
    margin-top: 8px !important;
}

.gridjs-pagination {

    .gridjs-pages button {
        margin-left: 0.3rem;
        border-radius: $border-radius !important;
        border: 1px solid $pagination-border-color;
        background-color: $pagination-bg;
        padding: 5px 12px;
        color: $pagination-color;

        &:last-child {
            border-right: 1px solid $pagination-border-color;
        }

        &:disabled,
        &:hover:disabled,
        &[disabled] {
            color: $pagination-disabled-color;
            background-color: $pagination-bg;
        }

        &:hover {
            background-color: $pagination-hover-bg;
            color: $pagination-hover-color;
        }

        &:focus {
            box-shadow: none;
            margin-right: 0;
        }

        &:last-child,
        &:first-child {
            &:hover {
                background-color: transparent;
            }
        }

        &.gridjs-currentPage {
            background-color: $pagination-active-bg;
            color: $pagination-active-color;
            border-color: $pagination-active-border-color;
            font-weight: $font-weight-medium;
        }
    }
}

.gridjs-search {
    position: relative;
    float: left;
    
    &::before {
        font-family: tabler-icons!important;
        content: "\eb1c";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        font-size: 16px;
        color: var(--#{$prefix}secondary-color);
    }
}

input.gridjs-input {
    border-color: $input-border-color;
    background-color: $input-bg;
    color: $input-color;
    line-height: $input-line-height;
    padding: $input-padding-y $input-padding-x $input-padding-y $input-padding-x*2.5;
    border-radius: $input-border-radius;
    @include font-size($input-font-size);

    &:focus {
        box-shadow: none;
        border-color: $input-focus-border-color;
        background-color: $input-focus-bg;
    }

    &::placeholder {
        color: $input-placeholder-color;
    }
}

th.gridjs-th {
    .gridjs-th-content {
        float: none;
        display: inline-block;
        vertical-align: middle;
        font-weight: $font-weight-semibold;
    }
}

button.gridjs-sort {
    float: none;
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 20px;
}

th.gridjs-th-sort .gridjs-th-content {
    width: calc(100% - 10px);
}

button {

    &.gridjs-sort-asc,
    &.gridjs-sort-desc {
        background-size: 7px;
    }
}


// card table

.table-card {

    .gridjs-head {
        padding: 16px 16px 5px;
    }

    .gridjs-wrapper {
        border-top: 0;
        border-radius: 0;
        border-width: 1px 0;
    }

    .gridjs-footer {
        padding: 8px 16px;
    }
}

// gridjs selection
.gridjs-tr-selected {
    td {
        background-color: $table-active-bg;
    }

    .gridjs-td .gridjs-checkbox[type=checkbox] {
        background-color: $form-check-input-checked-bg-color;
        border-color: $form-check-input-checked-border-color;

        @if $enable-gradients {
            background-image: escape-svg($form-check-input-checked-bg-image),
            var(--#{$prefix}gradient);
        }

        @else {
            background-image: escape-svg($form-check-input-checked-bg-image);
        }
    }
}

.gridjs-td {
    .gridjs-checkbox {
        width: $form-check-input-width;
        height: $form-check-input-width;
        vertical-align: top;
        background-color: $form-check-input-bg;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: $form-check-input-border;
        appearance: none;
        @include transition($form-check-transition);

        &[type="checkbox"] {
            @include border-radius($form-check-input-border-radius);
        }
    }
}

.gridjs-border-none {

    td.gridjs-td,
    th.gridjs-th {
        border-right-width: 0;
        border-left-width: 0;
    }
}

.gridjs-loading-bar {
    background-color: var(--#{$prefix}secondary-bg);
}

[data-bs-theme="dark"] {
    button {
        &:is(.gridjs-sort-neutral, .gridjs-sort-asc, .gridjs-sort-desc) {
            filter: $btn-close-white-filter;
        }
    }
}