//
// popover.scss
//

@each $color,
$value in $theme-colors {
    .popover-#{$color} {
        --#{$prefix}popover-max-width: 200px !important;
        --#{$prefix}popover-border-color: #{transparent};
        --#{$prefix}popover-header-bg: rgba(255,255,255, .1) !important;
        --#{$prefix}popover-header-color: var(--#{$prefix}white) !important;
        --#{$prefix}popover-bg: var(--#{$prefix}#{$color});
        --#{$prefix}popover-body-color: var(--#{$prefix}white) !important;
        --#{$prefix}popover-body-padding-x: 1rem !important;
        --#{$prefix}popover-body-padding-y: .5rem !important;
    }
}